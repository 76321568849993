import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { map } from 'rxjs';
import { LocalizationService } from '@capital-access/common/localization';
import { ChipSize, FireflyDrawerService } from '@capital-access/firefly/components';
import { BaseReportDrawer } from '../../../components/report-drawer/base-report-drawer';
import { SECTIONS_LAYOUT_KEY } from '../../../constants';
import { saveReportLayoutSettings } from '../../../core/+state/report.actions';
import { SectionSelectorListItem } from '../../../core/models/report-sections';
import { createProfileReport } from '../../+state/profile-report.actions';
import { CreateProfileReportDto } from '../../models/profile-report.models';
import { ProfileReportLayout } from '../../models/profile-report-sections';

@Component({
  templateUrl: './profile-report-drawer-v2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileReportDrawerV2Component extends BaseReportDrawer<
  CreateProfileReportDto,
  Record<ProfileReportLayout, SectionSelectorListItem[]>
> {
  layout: ProfileReportLayout = ProfileReportLayout.OnePager;
  filteredSections: Record<string, SectionSelectorListItem[]> = {};

  chipSize = ChipSize.Sm;

  layoutOptions$ = this.localizationService.getLocalization(this.localizationScope).pipe(
    map((localization: Record<string, string>) => [
      { text: localization['onePager'], id: ProfileReportLayout.OnePager },
      { text: localization['detailed'], id: ProfileReportLayout.Detailed }
    ])
  );

  get settingsKey(): string {
    return this.layout === ProfileReportLayout.Detailed
      ? this.request!.profileType
      : `${this.request!.profileType}${this.layout}`;
  }

  override getSections(
    filteredSections: Record<ProfileReportLayout, SectionSelectorListItem[]>
  ): SectionSelectorListItem[] {
    return filteredSections[this.layout];
  }

  override onInjectInputs(inputs: {
    request?: CreateProfileReportDto;
    layout: ProfileReportLayout;
    filteredSections: Record<ProfileReportLayout, SectionSelectorListItem[]>;
  }) {
    this.layout = inputs.layout;
    this.filteredSections = inputs.filteredSections;
    super.onInjectInputs(inputs);
  }

  override saveSettings() {
    super.saveSettings();
    this.store.dispatch(
      saveReportLayoutSettings({
        key: `${this.request!.profileType}_${SECTIONS_LAYOUT_KEY}`.toUpperCase(),
        layout: this.layout.toString()
      })
    );
  }

  setLayout($event: unknown) {
    this.layout = ($event as { id: ProfileReportLayout }).id;
    this.form.controls.sections.patchValue(this.getSections(this.filteredSections));
    this.switchOffSetAsDefault();
  }

  get createReportAction(): Action {
    return createProfileReport({ request: this.request!, sections: this.getSelectedSection, layout: this.layout });
  }

  constructor(store: Store, drawerService: FireflyDrawerService, private localizationService: LocalizationService) {
    super(store, drawerService);
  }
}
